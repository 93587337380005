import FrontendHandler from "@/ts/components/Handlers/FrontendHandler";
import Template from "@/ts/components/Types/Template";
import {AnyType} from "@/ts/components/Types/AnyType";

/**
 * API Connector für JSON-Requests.
 */
export default class ApiHandler {

    /**
     * Erstellt eine URL mit den übergebenen Parametern.
     * @param url
     * @param params
     * @private
     */
    private static createUrl(url: string, params: { [key: string]: string }) {
        const searchParams = new URLSearchParams();
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                searchParams.append(key, params[key]);
            }
        }
        const queryString = searchParams.toString();
        url = url.startsWith("/") ? url.substring(1) : url;
        const separator = url.includes("?") ? "&" : "?";
        return `/${url}${separator}${queryString}`;
    }

    public static async getJsonFetch(
        getParams: { [key: string]: string },
        postParams?: { [key: string]: string },
        additionalFetchOptions?: RequestInit
    ): Promise<Response | any> {
        const url = ApiHandler.createUrl("json.php", getParams);

        if (!postParams) {
            postParams = {};
        }

        if (Object.keys(postParams).length > 0) {
            const postData = new URLSearchParams(postParams).toString();
            const fetchOptions: RequestInit = Object.assign(
                {
                    method: "POST",
                    credentials: "same-origin", // Wichtig für Session-Cookies
                    body: postData,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8", // Wichtig für korrekte Formatierung
                        "X-Requested-With": "XMLHttpRequest"
                    },
                },
                additionalFetchOptions || {}
            );
            // console.debug("fetchOptions", {fetchOptions, additionalFetchOptions});
            return fetch(url, fetchOptions);
        } else {
            return fetch(url);
        }
    }

    /**
     * Executes a Fetch request with the given parameters and returns the response as JSON.
     * @param getParams
     * @param postParams
     * @param {RequestInit} additionalFetchOptions
     */
    public static async getJsonResponse(
        getParams: { [key: string]: string },
        postParams?: { [key: string]: string },
        additionalFetchOptions?: RequestInit
    ): Promise<AnyType> {
        const response: Response | null = await ApiHandler.getJsonFetch(getParams, postParams, additionalFetchOptions);
        if (response === null) {
            return null;
        }
        const result = await response.json();
        if (result.status !== undefined) {
            switch (result.status) {
            case "success":
                break;
            case "already_taken":
                if (result.id !== 0) {
                    FrontendHandler.showErrorMessage("already exists");
                    return result.id;
                }
                break;
            case "insert_failed":
                FrontendHandler.showErrorMessage("insert failed");
                break;
            default:
                break;
            }
        }
        return result;
    }

    /**
     * Executes a Fetch request with the given parameters and returns the response as JSON.
     * @param dataTemplate
     * @param getParams
     * @param postParams
     */
    public static async getJsonDataFromTemplate(
        dataTemplate: Template,
        getParams: { [key: string]: string } = {},
        postParams: { [key: string]: string } = {}
    ) : Promise<AnyType[]|undefined> {
        const urlParams: string[] = [];

        if(getParams.url !== undefined){
            urlParams.push(getParams.url);
        }

        urlParams.push(`vw_type=${dataTemplate.vwType}`);
        urlParams.push(`vw_name=${dataTemplate.vwName}`);
        if(Object.hasOwn(dataTemplate.params, dataTemplate.vwId) && dataTemplate.vwId !== 0){
            urlParams.push(`vw_id=${dataTemplate.vwId}`);
        }

        for (const key in dataTemplate.params) {
            if (Object.hasOwn(dataTemplate.params, key)) {
                urlParams.push(`${key}=${dataTemplate.params[key]}`);
            }
        }

        let url: string;
        if(getParams.url !== undefined){
            url = urlParams.join("&");
            delete getParams.url;
        }else{
            url = `/index.php?${urlParams.join("&")}`;
        }

        const requestParams: {
            service: string;
            url: string;
            template: string;
            praesenz?: string;
        } = {
            service: "getViewContent",
            url: encodeURI(url),
            template: encodeURI(dataTemplate.path),
            praesenz: window.etData.presenceId.toString(),
            ...getParams,
        };

        const rawResponse = await this.getJsonResponse(requestParams, postParams);
        return JSON.parse((rawResponse as { data?: string })?.data || "[]");
    }
}
